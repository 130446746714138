import React, { useContext, useEffect, useState } from 'react'
import Taquilla from './Taquilla'
import { SearchContext } from '../context/SearchContext'
import context from 'react-bootstrap/esm/AccordionContext';

const TaquillaContainer = () => {

    const {
        showAlertModal, handleOpenAlertModal, 
        handleCloseAlertModal,
        idaSeleccionada, vueltaSeleccionada,
        pax,
        listaPasajeros, setListaPasajeros,
        setStep,
        loading, setLoading,
        idsReserva, setIdsReserva,
        setUrlTicket,
    } = useContext(SearchContext);

 

    const volverPaso2 = () => {
        //vaciamos lista pasajeros.
        setListaPasajeros({
            ida: [],
            vuelta: []
        });
        //volvemos al paso 2.
        setStep("paso2");
    }

    useEffect(() => {
        if (idsReserva["ida"]) {
            setStep("paso4");
        }
    }, [idsReserva]);

    const comprarPasajes = async () => {

       

        if (idaSeleccionada !== undefined && pax == listaPasajeros['ida'].length && (!vueltaSeleccionada || pax == listaPasajeros['vuelta'].length)) {

            let currentToken = sessionStorage.getItem('currentToken');
            //Reservar la ida y la vuelta si corresponde.

            await comprarPasaje("ida", currentToken, idaSeleccionada, listaPasajeros["ida"]);
            if (vueltaSeleccionada != null) {
                await comprarPasaje('vuelta', currentToken, vueltaSeleccionada, listaPasajeros["vuelta"]);
            }
            if (idsReserva["ida"]) {
                setStep("paso4");
            }
        }
        else {
            handleOpenAlertModal('Debe completar la lista de todos los pasajeros');
        }

    }

    const comprarPasaje = async (tramo, token, tramoSeleccionado, listaPasajeros) => {
        // La compra del pasaje se hace en dos etapas 
        // 1º la reserva /buses/reserva 
        // 2º la compra/ emisión de ticket /buses/reservas/{{idReserva}}/confirmacion

        ///////////////////////////////// 1º Reservar //////////////////////////////////
        setLoading(true);
        try {
            const response = await fetch(process.env.REACT_APP_API + `/buses/reservas`, {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "X-Requested-With",
                    "token": token
                },
                method: 'POST',
                body: JSON.stringify(
                    {
                        "Servicios": [
                            {
                                "codigoServicio": tramoSeleccionado.codigoServicio,
                                "items": listaPasajeros
                            }
                        ]
                    }
                )
            });

          

            const data = await response.json();

            if(data.codigoResultado == 2290){
                setLoading(false);
                handleOpenAlertModal('Crédito insuficiente para el boleto que intenta emitir.');
                return;
            }
           

            if (data.message) alert(data.mensaje);

            setIdsReserva(prevIdsReserva => (
                {
                    ...prevIdsReserva,
                    [tramo]: data.idReserva
                }
            ));


            ///////////////////// 2º Paso, confirmación reserva! //////////////////////////////
            if (data.idReserva) {
                const response = await fetch(process.env.REACT_APP_API + `/buses/reservas/${data.idReserva}/confirmacion`, {
                    headers: {
                        "Content-type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "X-Requested-With",
                        "token": token
                    },
                    method: 'POST',
                    body: JSON.stringify(
                        {
                            "FormaDePago": 1,
                            "idMedioPago": null,
                            "Cuotas": 1,
                            "PorcentajeInteres": 0,
                            "ModoDeRecaudacion": 1,
                            "InformacionDePagoAprobado": {
                                "IdPago": null
                            },
                            "DescuentoComision": {
                                "DescuentoP10": 0,
                                "DescuentoPV": 0
                            }
                        }
                    )
                });
                const dataConfirm = await response.json();
              
                if (data.message) alert(data.mensaje);

                setUrlTicket(prevUrlTickets => (
                    {
                        ...prevUrlTickets,
                        [tramo]: dataConfirm.items[0].urlTicket ?? ""
                    }
                ));
            } else {
                console.log("Error: No hubo 'idReserva' en el paso previo.")
            }

        } catch (error) {
            handleOpenAlertModal('Hubo un error, por favor intente nuevamente')
        } finally{
            setLoading(false);
        }
    }

   

    return (
        <>

            <Taquilla handleOpenAlertModal={handleOpenAlertModal} handleCloseAlertModal={handleCloseAlertModal} showAlertModal={showAlertModal} tramoSeleccionado={idaSeleccionada} direccion="ida" vueltaSeleccionada={vueltaSeleccionada} idaSeleccionada={idaSeleccionada} volverPaso2={volverPaso2} comprarPasajes={comprarPasajes}
                showButtons={!vueltaSeleccionada}
            />
            {vueltaSeleccionada &&
                <Taquilla handleOpenAlertModal={handleOpenAlertModal} handleCloseAlertModal={handleCloseAlertModal} showAlertModal={showAlertModal} tramoSeleccionado={vueltaSeleccionada} direccion="vuelta" vueltaSeleccionada={vueltaSeleccionada} idaSeleccionada={idaSeleccionada} volverPaso2={volverPaso2} comprarPasajes={comprarPasajes} showButtons={true} />
            }



        </>
    )
}

export default TaquillaContainer