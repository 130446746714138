import React, { useContext, useState } from 'react'
import data from '../paradas';
import { SearchContext } from '../context/SearchContext';


const SearchBox = (props) => {

    const { handleOrigenNuevo, handleDestinoNuevo } = props

    const {
        origen, setOrigen,
        destino, setDestino,
        origenId, setOrigenId,
        destinoId, setDestinoId,
        step
    } = useContext(SearchContext);

    const [value, setValue] = useState("");
    const [showOptions, setShowOptions] = useState(true);

    const handleChange = (event) => {
        setValue(event.target.value);
        setShowOptions(true);

        //Estos condicionales sirven para que no se envíen datos escritos por el usuario y que no esten seleccionados del dropdown
        if(step === "paso1" && props.type === "origen"){
            setOrigen('')
        }
        if(step === "paso1" && props.type === "destino"){
            setDestino('')
        }
        if ((step === "paso2" || step === "paso2b") && props.type === "origen") {
            handleOrigenNuevo("")
        }
        if ((step === "paso2" || step === "paso2b") && props.type === "destino") {
            handleDestinoNuevo("")
        }
    }

    const handleParadaClick = (nombre) => {
        setShowOptions(false);
        setValue(nombre);
        const parada_id = (data.filter((p) => p.nombre === nombre)[0].id);
        if (props.type == "origen") {
            setOrigen(nombre);
            setOrigenId(parada_id);

            if (step === "paso2" || step === "paso2b") {
                handleOrigenNuevo(nombre)
            }
        }
        else {
            setDestino(nombre);
            setDestinoId(parada_id);
            if (step === "paso2" || step === "paso2b") {

                handleDestinoNuevo(nombre)
            }
        }
    }

    return (
        <>
            <div className="search-inner" style={{ position: "relative" }}>
                <label className={props.labelClass} htmlFor="name_contact">{props.title}</label>
                <input type="text" className="form-control" value={value} onChange={handleChange} required="required" />
            </div>
            <div className="dropdown" style={{ position: "absolute", maxHeight: "250px", overflow: "auto", zIndex: 1000 }} >
                {

                    value.length >= 2 && data.filter((p) => {
                        const search = value.toLocaleLowerCase();
                        const parada = p.nombre.toLocaleLowerCase();
                        const localidad = p.localidad.toLocaleLowerCase();
                        

                        return (
                            search  
                            && showOptions 
                            && (parada.includes(search) || localidad.includes(search))
                        );
                    })
                        .map((p) => (
                            <div key={p.id} className="dropdown-row" style={{ color: "black" }} onClick={() => handleParadaClick(p.nombre)}>
                                {p.nombre} - {p.localidad} - {p.provincia}
                            </div>
                        ))


                }
            </div>
        </>
    )
}

export default SearchBox