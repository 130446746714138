import React, { useContext, useEffect, useState } from 'react'
import ListItem from './ListItem'
import SearchBox from './SearchBox';
import { SearchContext } from '../context/SearchContext';
import AlertMessage from './AlertMessage';

const Results = ({ tramo }) => {

    const {
        showAlertModal,
        handleCloseAlertModal,
        handleOpenAlertModal,
        step, setStep,
        servicios, setServicios,
        origen, setOrigen,
        destino, setDestino,
        origenId, setOrigenId,
        destinoId, setDestinoId,
        ida, setIda,
        vuelta, setVuelta,
        pax, setPax,
        loading, setLoading,
        idaSeleccionada, setIdaSeleccionada,
        vueltaSeleccionada, setVueltaSeleccionada,
        handleSearch
    } = useContext(SearchContext);

    const [minDate, setMinDate] = useState('');
    const [origenNuevo, setOrigenNuevo] = useState('');
    const [destinoNuevo, setDestinoNuevo] = useState('');


    const handleIdaChange = (value) => {
        setIda(value);
    }
    const handleVueltaChange = (value) => {
        setVuelta(value);
    }
    const handlePaxChange = (value) => {
        setPax(value);
    }

    const handleOrigenNuevo = (or) => setOrigenNuevo(or);
    const handleDestinoNuevo = (des) => setDestinoNuevo(des);

    const handleNewSearch = (event) => {
        event.preventDefault();
        if(!destinoNuevo || !origenNuevo){
            handleOpenAlertModal('Por favor seleccione una ciudad de origen y/o destino válida.')
            return
        }else{
            handleSearch()
        }
    }


    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setMinDate(formattedDate);
        setIda(null)
       
    }, []);

    return (
        <>
         {
            showAlertModal.visible && <AlertMessage handleCloseAlertModal={handleCloseAlertModal} showAlertModal={showAlertModal} text={showAlertModal.message} />
        }
            <div id="results">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4>Pasajes a <strong>{servicios.paradaDestino.nombre}:</strong> Mostrando {servicios.servicios.length} resultados</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container margin_60_35">
                <div className="row">
                    <aside className="col-lg-3 aside-form" id="sidebar">
                        <div className="fadeInUp animated box_general results_search-params">
                            <div>
                                <div id="message-contact"></div>
                                <form method="post" action="list.html" id="contactform" onSubmit={(e) => handleNewSearch(e)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <SearchBox title="Origen" type="origen" setParada={setOrigen} handleOrigenNuevo={handleOrigenNuevo} labelClass="icon-location-outline text-center w-100" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <SearchBox title="Destino" type="destino" setParada={setDestino} handleDestinoNuevo={handleDestinoNuevo} labelClass="icon-location-2 text-center w-100" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="icon-calendar text-center w-100" htmlFor="date-from"> Partida</label>
                                                <input min={minDate} required type="date" id="date-from" name="date-from" className="form-control" onChange={(e) => handleIdaChange(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="icon-calendar text-center w-100" htmlFor="date-to"> Regreso</label>
                                                <input min={ida} type="date" id="date-to" name="date-to" className="form-control" onChange={(e) => handleVueltaChange(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="icon-users  text-center w-100" htmlFor="passengers"> Pasajeros</label>
                                                <input type="number" value={pax} min={1} id="passengers" name="passengers" required="required" className="form-control"
                                                    onChange={(e) => handlePaxChange(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group text-center">
                                                <input type="submit" value="Buscar Pasajes" className="btn_1 add_top_30" id="submit-contact" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div>©<script>document.write(Date().split(' ')[3])</script> - Provisto por Plataforma 10 SA </div>
                                    </div>
                                </form>
                            </div>
                            {/* <!-- /col --> */}
                        </div>
                    </aside>

                    <div className="col-lg-9 fadeInUp animated">

                        <div className="row gx-3">
                            <div className="col-6">
                                <div className={`list_title trim_title ${tramo === "ida" ? "active" : ""}`}>
                                    <h3>IDA</h3>
                                    <p>De {origen} a {destino} </p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`list_title trim_title ${tramo === "vuelta" ? "active" : ""}`}>
                                    <h3>VUELTA</h3>
                                    <p>De {destino} a {origen}</p>
                                </div>
                            </div>
                        </div>
                      

                        <div className="row column-titles d-none d-lg-flex ">
                            <div className="col-lg-2">
                                <small>Empresa</small>
                            </div>
                            <div className="col-lg">
                                <small>Salida</small>
                            </div>
                            <div className="col-lg">
                                <small>Llegada</small>
                            </div>
                            <div className="col-lg">
                                <small>Comodidad</small>
                            </div>
                            <div className="col-lg">
                                <small>Informacion</small>
                            </div>
                            <div className="col-lg-2">
                                <small>Precio</small>
                            </div>
                        </div>
                        {/* <!--/Column Titles --> */}
                        {servicios.servicios.length > 0 ? "" :


                            <div className="list-item box_general">
                                <div className="row text-center">
                                    <small> No hay servicios disponibles.</small>
                                </div>
                            </div>
                        }

                        {servicios.servicios.map((s) => {

                            return <ListItem key={s.codigoServicio} servicio={s} tramo={tramo} />

                        })
                        }

                      
                    </div>
                </div>
            </div>
        </>
    )
}

export default Results