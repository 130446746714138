import React from "react";
import '../styles/Taquilla.css'
const SeatMap = ({ handleOpenModal, mapaButacas, modalClick, direccion }) => {
  return (
    <div className="d-flex flex-wrap flex-md-nowrap gap-5 justify-content-center  " > {/* Contenedor horizontal */}
      {mapaButacas.secciones.map((seccion, sectionIndex) => (
        <div key={sectionIndex} >
          <h3 style={{ textAlign: "center", marginBottom: '20px' }}>
            {sectionIndex === 0 ? "Planta Baja" : "Planta Alta"} {/* Nombres personalizados */}
          </h3>
          <div
            style={{
              display: "grid",
              gridTemplateRows: `repeat(${seccion.filas}, 1fr)`,
              gridTemplateColumns: `repeat(${seccion.columnas}, 1fr)`,
              gap: "10px",
              border: "1px dotted #555555",
              borderRadius: '10px',
              padding: "10px",
              backgroundColor: '#F5F5F5'
            }}
          >
            {Array.from({ length: seccion.filas * seccion.columnas }).map(
              (_, index) => {
                const fila = Math.floor(index / seccion.columnas);
                const columna = seccion.columnas - 1 - (index % seccion.columnas); // Invertir eje Y
                const asiento = seccion.items.find(
                  (item) => item.x === fila && item.y === columna
                );

                  const isGris = !asiento || asiento.numero === 0;
                  const isDisponible = asiento && !asiento.ocupado && asiento?.numero !== 0;

                return (
                  //ASIENTOS
                  <div
                    onClick={() => {
                      if (isDisponible) {
                        handleOpenModal();
                        modalClick(`${asiento.numero}`);
                      }
                    }}
                    key={index}
                    className={` 
                    ${index === 0 && sectionIndex === 0
                        ? 'bus-chofer'
                        : !asiento || asiento.numero === 0
                          ? 'no-sat'
                          : asiento.seleccionado
                            ? 'asiento-seleccionado'
                            : asiento.ocupado
                              ? 'asiento-ocupado'
                              : 'asiento-disponible'}`
                    }
                    style={{
                      backgroundColor:
                        index === 0
                          ? ""
                          : asiento?.numero === 0 && sectionIndex === 0
                            ? null // No aplica color si tiene la clase 'bus-driver'
                            : isGris
                              ? "#F5F5F5" // Fondo gris
                              : asiento.ocupado
                                ? "red" // Fondo rojo si está ocupado
                                : "green", // Fondo verde si está disponible
                      color: isGris ? "black" : "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      height: "32px",
                      width: "32px",
                      pointerEvents: isDisponible ? "auto" : "none", // Habilita o deshabilita el clic
                      cursor: isDisponible ? "pointer" : "default", // Cambia el cursor para indicar si es clickeable
                    }}
                  >
                    <span style={{ color: `${asiento?.ocupado ? 'white' : 'black'}` }}>

                      {asiento?.numero || ""}
                    </span>
                  </div>
                );
              }
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SeatMap;
