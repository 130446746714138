import React, { useContext, useEffect, useState } from 'react'
import SearchBox from './SearchBox'
import { SearchContext } from '../context/SearchContext'
import AlertMessage from './AlertMessage';


const BuscadorHome = () => {



    const {
        showAlertModal,
        handleCloseAlertModal,
        handleOpenAlertModal,
        servicios, setServicios,
        origen, setOrigen,
        destino, setDestino,
        origenId, setOrigenId,
        destinoId, setDestinoId,
        ida, setIda,
        vuelta, setVuelta,
        pax, setPax,
        loading, setLoading,
        idaSeleccionada, setIdaSeleccionada,
        vueltaSeleccionada, setVueltaSeleccionada,
        handleSearch, handleFastSearch
    }
        = useContext(SearchContext);

    const [minDate, setMinDate] = useState('');



    const handleIdaChange = (value) => {
        setIda(value);
    }
    const handleVueltaChange = (value) => {
        setVuelta(value);
    }
    const handlePaxChange = (value) => {
        setPax(value);
    }


    useEffect(() => {
        setPax(1);
    }, [setPax])


    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setMinDate(formattedDate);
        setIda(null)
        setOrigen('')
        setDestino('')
    }, []);



    return (

        <>
        {
            showAlertModal.visible && <AlertMessage handleCloseAlertModal={handleCloseAlertModal} showAlertModal={showAlertModal} typeMessage={showAlertModal.typeMessage} text={showAlertModal.message} />
        }
        <div>
            <div className="header-video" style={{ width: "100%", height: "600px" }}>
                <div id="hero_video">
                    <div className="content">
                        <h3 className="fadeInUp animated">Bienvenidos</h3>
                        <p className="fadeInUp animated">
                            Encontrá los mejores precios en pasajes de ómnibus a todo el país y limítrofes
                        </p>
                        <div>
                            <div>
                                <div id="message-contact"></div>

                                <form onSubmit={handleSearch} method="post" action="list.html" id="contactform" >
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <div className="form-group">
                                                <SearchBox title="Origen" type="origen" />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="form-group">
                                                <SearchBox title="Destino" type="destino" />
                                            </div>
                                        </div>

                                        <div className="col-lg-2 col-md-5">
                                            <div className="form-group">
                                                <label htmlFor="date-from hover:cursor-pointer">Partida</label>
                                                <input type="date" id="date-from" min={minDate} name="date-from" required="required" className="form-control" onChange={(e) => handleIdaChange(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-5">
                                            <div className="form-group ">
                                                <label htmlFor="date-to">Regreso</label>
                                                <input type="date" disabled={!ida} min={ida} id="date-to" name="date-to" className="form-control" style={{
                                                    backgroundColor: !ida ? "#E2E3E5" : "white",
                                                    color: !ida ? "gray" : "black"
                                                }} onChange={(e) => handleVueltaChange(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-1 col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="passengers">Pasajeros</label>
                                                <input type="number" id="passengers" name="passengers" required="required" className="form-control"
                                                    value={pax}
                                                    min="1"
                                                    onChange={(e) => handlePaxChange(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-1">
                                            <div className="form-group">
                                                <input type="submit" value="Buscar Pasajes" className="btn_1 add_top_30" id="submit-contact" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div>©<script>document.write(Date().split(' ')[3])</script> - Provisto por Plataforma 10 SA </div>
                                    </div>
                                </form>


                            </div>

                        </div>
                    </div>
                </div>
                <img src="img/video_fix.png" alt="" className="header-video--media" data-video-src="video/intro" data-teaser-source="video/intro" data-provider="" data-video-width="1920" data-video-height="750" />
            </div>
        </div>
        </>
    )
}

export default BuscadorHome