import React, { useContext, useEffect, useState } from 'react'
import Header from '../components/Header'
import { useParams } from 'react-router-dom';
import BusRowXXOX from '../components/BusRowXXOX';
import BusRowXXOO from '../components/BusRowXXOO';
import SeatReferences from '../components/SeatReferences';
import CountryOptions from '../components/CountryOptions';
import BusRowEmpty from '../components/BusRowEmpty';
import BusRowDriver from '../components/BusRowDriver';
import PassengerModal from '../components/PassengerModal';
import { SearchContext } from '../context/SearchContext';

const TaquillaPage = () => {    
    let params = useParams();

    const 
        {servicios, setServicios,
        origen,setOrigen,
        destino,setDestino,
        origenId, setOrigenId,
        destinoId, setDestinoId,
        ida, setIda,
        vuelta, setVuelta,
        pax, setPax
        } = useContext(SearchContext);

    const [taquilla, setTaquilla] = useState();
    const [butacas, setButacas] = useState([]); //array ( bucata, bool ocupaciópn)
    const [ultButacaSeleccionada, setUltButacaSeleccionada] = useState();
    const [listaPasajeros, setListaPasajeros] = useState([]);


    console.log(params.servicioId);
 
    const getTaquilla = async () => {

        try {
            let currentToken= sessionStorage.getItem('currentToken');
            console.log('GetServicios usa token:' + currentToken);
            const response = await fetch(`/buses/servicios/${params.servicioId}/taquilla`, {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",        
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "X-Requested-With",  
                    "token": currentToken
                },
                method: 'GET' 
            });
            const data = await response.json();
            console.log(data);
            setTaquilla(data);
            parseButacas(data.mapaButacas.secciones);
        } catch (error) {
            const msg= "Error " + error.message;
            alert(msg);
            console.log(msg);
        }
    }

    const parseButacas = (secciones) =>{
        let ret= [];
        let sec1= secciones[0].items;
        let sec2= secciones[1].items;

        sec1.forEach(item => {
            ret[item.numero] = item.ocupado;
        });
        sec2.forEach(item => {
            ret[item.numero] = item.ocupado;
        });

        setButacas(ret);
        console.log(ret);                
    }

    const getAvailableClass = (index) => {
        let ret= butacas[index] ?  "bus-asiento-ocupado": "bus-asiento-disponible";
        return ret;
    }

    const tooglePassangerModal = (index) => {
        return butacas[index] ? {} : {"data-bs-toggle":"modal", "data-bs-target":"#passengerModal"};
    }

    const modalClick = (index) => {
        console.log("Butaca Seleccionada : ", index);
        setUltButacaSeleccionada(index);        
    }

    
    const reservar = async () => {
        
        try {
            let currentToken= sessionStorage.getItem('currentToken');
            console.log('Reservar usa token:' + currentToken);
            const response = await fetch(`/buses/reservas/`, {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",        
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "X-Requested-With",  
                    "token": currentToken
                },
                method: 'POST',
                body: JSON.stringify(
                    {
                        "Servicios":[
                            {
                                "codigoServicio":params.servicioId,
                                "items":[
                                {
                                    "butaca":16,
                                    "importe":39.2,
                                    /*Datos Carga Pasajero:*/
                                    "pasajero":{
                                        "nombre": "Edgardo",
                                        "apellido": "Alemis",
                                        "fechaNacimiento": "1980-04-11",
                                        "tipoDocumento": 3,
                                        "numeroDocumento": "123456789",
                                        "nacionalidad": 1,
                                        "residencia": 1,
                                        "razonSocial": "qweqweqwe",
                                        "tipoDocumentoFiscal": 1,
                                        "numeroDocumentoFiscal": "123456798",
                                        "idCondicionImpositiva": 1,
                                        "ocupacion": "test",
                                        "estadoCivil": "test",
                                        "sexo": "M",
                                        "email": "123@dasdas.com",
                                        "telefono": "12232131"
                                    }, 
                    
                                    "menuABordo": "alfajor"
                                }                               
                                ]
                            }
                        ]          
                    }
            )});
            const data = await response.json();
          
            setTaquilla(data);
            parseButacas(data.mapaButacas.secciones);
        } catch (error) {
            const msg= "Error " + error.message;
            alert(msg);
            console.log(msg);
        }
        
    }

    useEffect ( () => {
        getTaquilla();
    } ,[]);

    return (
    <div>        
        <div className="layer"></div>
        {/* <!-- Mobile menu overlay mask --> */}

        {/* <div id="preloader">
                <div data-loader="circle-side"></div>
            </div> */}
        {/* <!-- End Preload --> */}

        <Header />

        <main>
            <div id="results">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4>Elegí tus <strong>Asientos</strong></h4>
                        </div>
                    </div>	
                    {/* <!-- /row --> */}
                </div>
                {/* <!-- /container --> */}
            </div>
            {/* <!-- /results --> */}

            <div className="container margin_60">
                {/* <!-- PASSENGER --> */}
                <div className="row">
                    <aside className="col-xl-5 col-lg-5 bus-container">
                        <div className="box_general_3 booking">
                            <form>
                                <div className="row">
                                    <div className="col-6 text-center">
                                        <strong>Planta Baja</strong>
                                        <div className="bus">
                                            <BusRowDriver/>
                                            <BusRowEmpty/>
                                            <BusRowEmpty/>
                                            <BusRowEmpty/>
                                            <BusRowEmpty/>
                                            <BusRowXXOX a="1" b="2" c="3" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />
                                            <BusRowXXOX a="4" b="5" c="6" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />
                                            <BusRowXXOX a="7" b="8" c="9" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />
                                            <BusRowXXOX a="10" b="11" c="12" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />
                                            <BusRowXXOX a="13" b="14" c="15" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                            
                                        </div>
                                    </div>
                                    <div className="col-6 text-center">
                                        <strong>Planta Alta</strong>
                                        <div className="bus">
                                            <BusRowXXOX a="16" b="17" c="18" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                             
                                            <BusRowXXOX a="19" b="20" c="21" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOO a="22" b="23" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOO a="24" b="25" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOO a="26" b="27" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOO a="28" b="29" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                                                                     
                                            <BusRowXXOX a="30" b="31" c="32" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOX a="33" b="34" c="35" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOX a="36" b="37" c="38" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                            <BusRowXXOX a="39" b="40" c="41" getAvailableClass={getAvailableClass} tooglePassangerModal={tooglePassangerModal} modalClick={modalClick}  />                                                                                         
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <SeatReferences />
                        </div>
                        {/* <!-- /box_general --> */}
                    </aside>
                    {/* <!-- /aside --> */}

                    <div className="col-xl-7 col-lg-7 passenger-container">
                        <div className="box_general_3 cart">
                            <div className="form_title mb-4">
                                <h3><strong className="container-icon-trim"><i className="icon-right-2"></i></strong> {origen} A { destino } </h3>
                                <p>Tramo de Ida</p>
                            </div>

                            {/* <!--/Column Titles --> */}
                            <div className="list-item box_general wow fadeIn">
                                <div className="row text-center">
                                    <div className="col-lg-2">
                                        <small>Empresa</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Salida</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Llegada</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Comodidad</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Informacion</small>
                                    </div>
                                    <div className="col-lg-2">
                                        <small>Precio</small>
                                    </div>
                                </div>
                                <hr className="mt-0 mb-3" />
                                <div className="row">
                                    <div className="col-lg-2 col-md-12 item-company-name">
                                        <h6>Via Bariloche</h6>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <p>Mar 07 Mar 16:00hs.</p>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <p>Mar 07 Mar 16:00hs.</p>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <p>Cama Ejecutivo</p>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <div>
                                            Duracion: 13:00hs.<br />
                                            Pasajes: 36<br />
                                            <a href="#0" data-bs-toggle="modal" data-bs-target="#routeModal">Recorrido</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-12 item-price">
                                        <div>
                                            <h6><small>$</small>17.460,00</h6>
                                            <small>Por Persona</small><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            {/* <!-- Passengers List --> */}
                            { listaPasajeros.length >0 &&
                            <div className="passengers-list" >
                                <h5 className="mt-5">Lista de pasajeros</h5>                                
                                
                                <div className="row text-center passenger-list-titles">
                                    <div className="col-lg-1">
                                        <small>Butaca</small>
                                    </div>
                                    <div className="col-lg-3">
                                        <small>DNI</small>
                                    </div>
                                    <div className="col-lg-4">
                                        <small>Nombre</small>
                                    </div>
                                    <div className="col-lg-4">
                                        <small>Apellido</small>
                                    </div>
                                </div>

                                { listaPasajeros.map((p) => {
                                    return (

                                        <>
                                        <div className="row">
                                            <div className="col-lg-1 item-data">
                                                <p>{ p.Butaca}</p>
                                            </div>
                                            <div className="col-lg-3  item-data">
                                                <p>{p.DocumentoNumero}</p>
                                            </div>
                                            <div className="col-lg-4 item-data">
                                                <p>{p.Nombre}</p>
                                            </div>
                                            <div className="col-lg-4  item-price">
                                                <p>{p.Apellido}</p>
                                                {/* <a href="1" className="btn_1 small">Quitar</a> */}
                                            </div>
                                        </div>                                        
                                        </>
                                    )})}
                            </div>                            
                            }
                            {/* <!-- Passengers List --> */}

                            <hr />
                            {/* <!--End step --> */}

                            <div className="alert alert-secondary text-center" role="alert">
                                <p className="mb-0">
                                    <strong>¡IMPORTANTE!</strong><br />
                                    Si viaja con menores conozca la reglamentación vigente <a href="https://travelclick.plataforma10.com.ar/seguridad-transporte" >aquí</a>
                                </p>
                            </div>
                            <div className="alert alert-secondary text-center" role="alert">
                                Es obligatorio viajar con el documento declarado en este pasaje según <a href="https://www.boletinoficial.gob.ar/detalleAviso/primera/153519/20161108" >Res 76-E-2016</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /col --> */}
                    
                </div>
                {/* <!-- /row --> */}
                
                <div className="alert alert-warning mt-4" role="alert">
                    <div className="row">
                        <div className="col text-center d-flex align-items-center justify-content-center">
                            <i className="icon-warning-empty"></i>
                        </div>
                        <div className="col-11">
                            "Para saber los detalles de PreViaje ingresa al siguiente link" https://previaje.gob.ar/como-funciona
        Los requisitos sanitarios de ingreso a cada territorio debido al Covid-19 están determinados por las autoridades competentes, que pueden variar en el tiempo. Es responsabilidad del pasajero corroborar los requerimientos con la autoridad de cada jurisdicción antes del viaje. La ausencia de los requisitos es responsabilidad exclusiva del pasajero.
                        </div>
                    </div>
                </div>
                {/* <!-- /footer warning --> */}
                {/* <!-- /PASSENGER --> */}

                <hr className="separator-passengers" />

                {/* <!-- PASSENGER --> */}
                <div className="row">
                    <aside className="col-xl-5 col-lg-5 bus-container">
                        <div className="box_general_3 booking">
                            <form>
                                <div className="row">
                                    <div className="col-6 text-center">
                                        <strong>Planta Baja</strong>
                                        <div className="bus">
                                            <ul>
                                                <li className="bus-chofer"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                            </ul>
                                            <ul>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                            </ul>
                                            <ul>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                            </ul>
                                            <ul>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                            </ul>
                                            <ul>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1">1</a></li>
                                                <li className={getAvailableClass()}><a href="1">2</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">3</a></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">4</a></li>
                                                <li className={getAvailableClass()}><a href="1">5</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">6</a></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">7</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">8</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">9</a></li>
                                            </ul>
                                            <ul>
                                                <li className="bus-asiento-ocupado"><a href="1">10</a></li>
                                                <li className="bus-asiento-ocupado"><a href="1">11</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-asiento-ocupado"><a href="1">12</a></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">13</a></li>
                                                <li className={getAvailableClass()}><a href="1">14</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">15</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-6 text-center">
                                        <strong>Planta Alta</strong>
                                        <div className="bus">
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">16</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">17</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">18</a></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1">19</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">20</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">21</a></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">22</a></li>
                                                <li className={getAvailableClass()}><a href="1">23</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">24</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">25</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">26</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">27</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className="bus-vacio"></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">28</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">29</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1">3</a></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">30</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">31</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">32</a></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">33</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">34</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">35</a></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">36</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">37</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">38</a></li>
                                            </ul>
                                            <ul>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">39</a></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">40</a></li>
                                                <li className="bus-vacio"></li>
                                                <li className={getAvailableClass()}><a href="1" data-bs-toggle="modal" data-bs-target="#passengerModal">41</a></li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </form>
                            <SeatReferences />
                        </div>
                        {/* <!-- /box_general --> */}
                    </aside>
                    {/* <!-- /aside --> */}

                    <div className="col-xl-7 col-lg-7 passenger-container">
                        <div className="box_general_3 cart">
                            <div className="form_title mb-4">
                                <h3><strong className="container-icon-trim"><i className="icon-right-2"></i></strong> {destino} A {origen}</h3>
                                <p>Tramo de Vuelta</p>
                            </div>

                            {/* <!--/Column Titles --> */}
                            <div className="list-item box_general wow fadeIn">
                                <div className="row text-center">
                                    <div className="col-lg-2">
                                        <small>Empresa</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Salida</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Llegada</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Comodidad</small>
                                    </div>
                                    <div className="col-lg">
                                        <small>Informacion</small>
                                    </div>
                                    <div className="col-lg-2">
                                        <small>Precio</small>
                                    </div>
                                </div>
                                <hr className="mt-0 mb-3"/> 
                                <div className="row">
                                    <div className="col-lg-2 col-md-12 item-company-name">
                                        <h6>Via Bariloche</h6>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <p>Mar 07 Mar 16:00hs.</p>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <p>Mar 07 Mar 16:00hs.</p>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <p>Cama Ejecutivo</p>
                                    </div>
                                    <div className="col-lg col-6 item-data">
                                        <small>
                                            Duracion: 13:00hs.<br />
                                            Pasajes: 36<br />
                                            <a href="#0" data-bs-toggle="modal" data-bs-target="#routeModal">Recorrido</a>
                                        </small>
                                    </div>
                                    <div className="col-lg-2 col-md-12 item-price">
                                        <div>
                                            <h6><small>$</small>17.460,00</h6>
                                            <small>Por Persona</small><br />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="passengers-list">
                                <h5 className="mt-5">Lista de pasajeros</h5>
                                {/* <!--/Column Titles --> */}
                                
                                <div className="row text-center passenger-list-titles">
                                    <div className="col-lg-1">
                                        <small>N°</small>
                                    </div>
                                    <div className="col-lg-6">
                                        <small>Pasajero</small>
                                    </div>
                                    <div className="col-lg-3">
                                        <small>Precio</small>
                                    </div>
                                    <div className="col-lg-2">
                                        <small></small>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-1 col-6 item-data">
                                        <p>10</p>
                                    </div>
                                    <div className="col-lg-6 col-6 item-data">
                                        <p>Nombre Apellido</p>
                                    </div>
                                    <div className="col-lg-3 col-6 item-data">
                                        <p>ARS9.200,00</p>
                                    </div>
                                    <div className="col-lg-2 col-md-12 item-price">
                                        <a href="1" className="btn_1 small">Quitar</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-1 col-6 item-data">
                                        <p>11</p>
                                    </div>
                                    <div className="col-lg-6 col-6 item-data">
                                        <p>Nombre Apellido</p>
                                    </div>
                                    <div className="col-lg-3 col-6 item-data">
                                        <p>ARS9.200,00</p>
                                    </div>
                                    <div className="col-lg-2 col-md-12 item-price">
                                        <a href="1" className="btn_1 small">Quitar</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-1 col-6 item-data">
                                        <p>12</p>
                                    </div>
                                    <div className="col-lg-6 col-6 item-data">
                                        <p>Nombre Apellido</p>
                                    </div>
                                    <div className="col-lg-3 col-6 item-data">
                                        <p>ARS9.200,00</p>
                                    </div>
                                    <div className="col-lg-2 col-md-12 item-price">
                                        <a href="1" className="btn_1 small">Quitar</a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Passengers List --> */}

                            <hr />
                            {/* <!--End step --> */}

                            <div className="alert alert-secondary text-center" role="alert">
                                <p className="mb-0">
                                    <strong>¡IMPORTANTE!</strong><br />
                                    Si viaja con menores conozca la reglamentación vigente <a href="https://travelclick.plataforma10.com.ar/seguridad-transporte" >aquí</a>
                                </p>
                            </div>
                            <div className="alert alert-secondary text-center" role="alert">
                                Es obligatorio viajar con el documento declarado en este pasaje según <a href="https://www.boletinoficial.gob.ar/detalleAviso/primera/153519/20161108" >Res 76-E-2016</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /col --> */}
                    
                </div>
                {/* <!-- /row --> */}
                
                <div className="alert alert-warning mt-4" role="alert">
                    <div className="row">
                        <div className="col text-center d-flex align-items-center justify-content-center">
                            <i className="icon-warning-empty"></i>
                        </div>
                        <div className="col-11">
                            "Para saber los detalles de PreViaje ingresa al siguiente link" https://previaje.gob.ar/como-funciona
        Los requisitos sanitarios de ingreso a cada territorio debido al Covid-19 están determinados por las autoridades competentes, que pueden variar en el tiempo. Es responsabilidad del pasajero corroborar los requerimientos con la autoridad de cada jurisdicción antes del viaje. La ausencia de los requisitos es responsabilidad exclusiva del pasajero.
                        </div>
                    </div>
                </div>
                {/* <!-- /footer warning --> */}
                {/* <!-- /PASSENGER --> */}
                
                <div className="box_general_3 mt-4">
                    <h5 className="text-center">Subtotal: <small>$</small>34.920.-</h5>
                    <hr /> 
                    <div className="row">
                        <div className="col-lg-6 text-lg-start text-center mb-lg-0 mb-3">
                            <a href="list.html" className="btn_1 outline medium"><i className="icon-left-open-2"></i> Volver a Seleccionar</a>
                        </div>
                        <div className="col-lg-6 text-lg-end text-center">                            
                            <button onClick={reservar} className="btn_1 medium">Confirmar y comprar<i className="icon-right-open-2"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /container --> */}
        </main>
        {/* <!-- /main --> */}

        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="copy" style={{textAlign: "center"}}>Copyright © <script>document.write(Date().split(' ')[3])</script> Travelclick</div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <!--/footer--> */}

        <div id="toTop"></div>
        {/* <!-- Back to top button --> */}

        {/* <!-- Modal Recorrido--> */}
        <div className="modal fade" id="routeModal" tabIndex={-1} aria-labelledby="routeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title w-100 text-center" id="routeModalLabel">Recorrido</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>El servicio no informa el recorrido.</p>
                </div>
            </div>
            </div>
        </div>
        {/* <!-- /Modal Recorrido--> */}


        {/* <!-- Modal Passenger--> */}        
        
        <PassengerModal listaPasajeros={listaPasajeros} setListaPasajeros={setListaPasajeros} ultButacaSeleccionada={ultButacaSeleccionada} />
        {/* <!-- /Modal Passenger--> */}

        {/* <!-- COMMON SCRIPTS --> */}

 

    </div>
    )
}

export default TaquillaPage