import { useContext } from 'react';
import '../styles/AlertMessage.css'
import { SearchContext } from '../context/SearchContext';

const AlertMessage = ({ handleCloseAlertModal, text, typeMessage }) => {

  const {setStep} = useContext(SearchContext)

  return (
    <>
      <div className="custom-modal-backdrop"></div>
      <div className={`custom-modal `}>
        <div className="modal-dialog p-4">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title w-100 text-center" id="passengerModalLabel">
                {text}
              </h5>

            </div>

              <div className="modal-footer">
              {
                typeMessage === "error en getServicios" ?
                <button type='button' onClick={()=> {handleCloseAlertModal(); setStep("paso1")}} style={{background: "red"}} className="btn_1 m-auto mt-2">
                Aceptar
              </button>
                :
                typeMessage === "error en getTaquilla" ?
                <button type='button' onClick={()=> {handleCloseAlertModal(); setStep("paso2")}} style={{background: "red"}} className="btn_1 m-auto mt-2">
                Aceptar
              </button>
                :
                <button type='button' onClick={handleCloseAlertModal} className="btn_1 m-auto mt-2">
                  Aceptar
                </button>
                }
              </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default AlertMessage;
