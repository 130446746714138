import React, { useState, createContext, createFactory } from 'react'

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {

    const [step, setStep] = useState('paso1');
    const [servicios, setServicios] = useState();
    const [origen, setOrigen] = useState("");
    const [destino, setDestino] = useState("");
    const [origenId, setOrigenId] = useState(null);
    const [destinoId, setDestinoId] = useState(null);
    const [ida, setIda] = useState('');
    const [vuelta, setVuelta] = useState('');
    const [pax, setPax] = useState(0);
    const [loading, setLoading] = useState(false);

    const [idaSeleccionada, setIdaSeleccionada] = useState();
    const [vueltaSeleccionada, setVueltaSeleccionada] = useState();
    const [listaPasajeros, setListaPasajeros] = useState({
        ida: [],
        vuelta: []
    }
    );
    const [idsReserva, setIdsReserva] = useState(
        {
            "ida": null,
            "vuelta": null
        });
    const [urlTicket, setUrlTicket] = useState(
        {
            "ida": null,
            "vuelta": null
        }
    );
    const [showAlertModal, setShowAlertModal] = useState({
        visible: false,
        message: "",
        typeMessage: ""
    });

    const handleOpenAlertModal = (message, typeMessage) => {
        setShowAlertModal({ visible: true, message, typeMessage });

    };

    const handleCloseAlertModal = () => {
        setShowAlertModal({ visible: false, message: "" });
    };

    const handleSearch = async (event) => {
        if (event) {
            event.preventDefault();
        }

        if (!origen || !destino) {
            handleOpenAlertModal('Por favor, seleccione una ciudad de origen y/o destino válida.')
            return;
        }

        const fechaRegreso = document.getElementById("date-to").value;
        if (!fechaRegreso) {
            setVuelta('')
            setVueltaSeleccionada(null)
        }

        const success = await getServicios(origenId, destinoId, ida);

        if (success) {
            setStep('paso2');
        }
    }

    const handleFastSearch = () => {

        //para evitar problemas de asyncronismo
        const newOrigenId = 10;
        const newDestinoId = 9;
        //const newIda = '2024-09-27';
        const today = new Date();
        const nextWeek = new Date(today);
        nextWeek.setDate(today.getDate() + 7);
        // Format the date as YYYY-MM-DD
        const newIda = nextWeek.toISOString().split('T')[0];
        const newPax = 1;

        setOrigenId(newOrigenId);
        setOrigen('Retiro');
        setDestinoId(newDestinoId);
        setDestino('Mar del Plata');
        setIda(newIda);
        setPax(newPax);

        // console.log(`OrigenIdorigenId: ${newOrigenId} - DestinoId: ${newDestinoId} - Ida: ${newIda} - vuelta ${vuelta} - Pax: ${newPax} `); 
        getServicios(newOrigenId, newDestinoId, newIda);
        setStep('paso2');
    }

    const comprar = (servicio, tramo) => {
        if (tramo === "ida") {
            // console.log("Servicio Ida: " + servicio);
            setIdaSeleccionada(servicio);

            if (vuelta !== '') {
                getServicios(destinoId, origenId, vuelta, 'vuelta');
                setStep("paso2b");
            }
            else {
                setStep('paso3'); //si no hay "vuelta" vamos a la taquilla
            }
        }
        else { //vuelta
            setStep("paso3");
            setVueltaSeleccionada(servicio);
            // console.log("Servicio Vuelta: " + servicio);
        }
    }

    const fancyDate = (d) => {
        let ret = new Date(d);
        return ret.toLocaleString();
    }

    const getServicios = async (oriId, destId, fecha, tramo) => {
        setLoading(true);
        let currentToken = sessionStorage.getItem('currentToken');
        // console.log('GetServicios usa token:' + currentToken);

        try {
            const response = await fetch(process.env.REACT_APP_API + `/buses/busqueda/${oriId}/${destId}/${fecha}`, {
                headers: {
                    "Content-type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "X-Requested-With",
                    "token": currentToken
                },
                method: 'GET'
            });
            if (!response.ok) {
                throw new Error('Error en la solicitud de servicios');
            }
            const data = await response.json();

            setServicios(data);
            if (tramo === 'vuelta' && data.servicios.length < 1) {
                setVuelta('')
                setVueltaSeleccionada(null)
            }
            return true;
        } catch (error) {
            handleOpenAlertModal('Hubo un error, intente nuevamente', "error en getServicios");

            // En caso de error, devolvemos false
            return false;
        } finally {

            setLoading(false);
        }
    }


    return (
        <SearchContext.Provider value={
            {
                handleCloseAlertModal,
                handleOpenAlertModal,
                showAlertModal,
                step, setStep,
                servicios, setServicios,
                origen, setOrigen,
                destino, setDestino,
                origenId, setOrigenId,
                destinoId, setDestinoId,
                ida, setIda,
                vuelta, setVuelta,
                pax, setPax,
                loading, setLoading,
                idaSeleccionada, setIdaSeleccionada,
                vueltaSeleccionada, setVueltaSeleccionada,
                listaPasajeros, setListaPasajeros,
                handleSearch, handleFastSearch,
                comprar,
                fancyDate,
                idsReserva, setIdsReserva,
                urlTicket, setUrlTicket
            }
        }>
            {children}
        </SearchContext.Provider>
    );

}
